<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Choices from "choices.js";

import { getCashApi } from '@/api/cash'






/**
 * User list component
 */
export default {
  page: {
    title: "EFT Payment",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "EFT List",
      items: [
      {
          text: "Bank & Cash",
          href: "/",
        },
        {
          text: "EFT List",
          href: "/",
          active : true
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "value",
      sortDesc: false,

      eft_number : '',
      fields: [


        {
          key: "ID",
          label : '',
          sortable: true,
           tdClass: 'align-middle'
        },
        {
          key: "BankAccount",
          label : 'Bank Account',
          sortable: true,
           tdClass: 'align-middle'
        },

        {
          key: "Number",
          sortable: true,
           tdClass: 'align-middle'
        },

       
        {
          key: "Amount",
          sortable: false,
           tdClass: 'align-middle'
        },

        {
          key: "PostDate",
          sortable: true,
           tdClass: 'align-middle'
        },
        { key : 'EFTFile',  tdClass: 'align-middle'},
        {
          key: "Description",
          sortable: true,
           tdClass: 'align-middle'
        },
        {key : 'Action',  tdClass: 'align-middle'}

      ],
     
      selected_transactions: [],
      account_list         : [],
      bank_id              : '',
      acount_choice_obj    : {},
    };
  },
  components: {
    Layout,
    PageHeader,
    
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },

  },

  created() {
    getCashApi().bank_account_list({last_reconciled_date : 1}).then((res) =>{
      this.account_list.push({
          label: "All",
          value : '',
          account: "",
          selected : true
        })
      if (res.errCode == 0) {
        res.data.accounts.map((a)=>{
          this.account_list.push({
            label       : a.name,
            value       : a.id,
            account     : a.a_account,
            selected    : false
          })
        })

        this.acount_choice_obj.setChoices(this.account_list)
      }
    })

    this.queryList()
  },
  mounted() {
    this.acount_choice_obj= new Choices('#bank_account', {
      placeholderValue: "search account",
      searchPlaceholderValue: "search by name",
    })

  },
  methods: {

    queryList() {

      let data = {
        page: {
          page_no  : this.currentPage,
          page_size: this.perPage
        },
        eft_number : this.eft_number
      }

      if (this.bank_id) {
        data.bank_id = this.bank_id
      }

      getCashApi().eft_transaction_list(data).then((res) => {
        this.list = []
        res.data.map((o) => {
          this.list.push(o)
          this.totalRows = res.page.total;

        })

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },

    changeAccount() {
      this.queryList()
    },


    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },



    onSearch() {
      this.currentPage = 1;
      this.queryList()
    },




    completeEFTTransaction(eft) {
      console.log(eft)
    },

    finishEFT(eft) {
      this.$router.push({ name : 'eft-completion', query: {eft_number : `${eft.transaction_code}` }})
    },

    cancelEFT(eft) {
      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Cancel EFT?",
        () => {
          getCashApi().cancel_eft({eft_id : eft.id}).then(res => {
            if (res.errCode  == 0) {
              eft.status ='CANCELLED'
            } else {
              this.$alertify.error(" Cancel Failed " + res.errCode);
            }
          })
        })
    },

    onSelectedTransaction(evt, t)  {
      if (evt.target.checked == true) {
        let d = this.selected_transactions.find((e)=> e.id == t.id)
        if (!d) {
          this.selected_transactions.push(t)
        }
      } else {
        this.selected_transactions = this.selected_transactions.filter((e)=> e.id != t.id)
      }
    }
  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">

          
          <div class="card-body">

            <div class="row">
              <div class="col-md-4">
                <div class="mb-3">
                  <h5 class="card-title">
                    Total
                    <span class="text-muted fw-normal ms-2">({{ totalRows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-8">           
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >

                <label class="mt-2"></label>
                <div class="col-md-3">
                 
                </div>

               
                <label class="mt-2 ml-3">Account:</label>
                <div class="search-box col-md-4">
                    <div class="position-relative">
                      <select
                          class="form-control"
                          data-trigger
                          name="account"
                          id="bank_account"
                          v-model ="bank_id"
                          @change="changeAccount"
                          >
                          <option value=" "></option>
                        </select>
                      
                    </div>
                  </div>

                
                  
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
 

            <div class="table-responsive" style="min-height: 60dvh;">
              <div class="table align-middle table-nowrap">

                <b-table :items="list" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
                striped hover
                  :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered"
                  class="table-check">

                  <template #cell(ID)="row">
                    <feather :type="row.detailsShowing? 'chevron-down' :'chevron-right'" @click="row.toggleDetails"></feather>
                  </template>

                  <template #cell(BankAccount)="data">
                    {{ data.item.remit_bank_name }}
                  </template>

                  <template #cell(Number)="data">
                    {{ data.item.transaction_code }}
                  </template>

                  <template #cell(Amount)="data">
                    ${{ data.item.amount.toLocaleString() }}
                  </template>

                  <template #cell(PostDate)="data">
                    {{ data.item.transaction_time }}
                  </template>

                  <template #cell(Description)="data">
                    {{ data.item.remarks }}
                  </template>

                  <template #cell(EFTFile)="data">
                    <b-link :href="data.item.eft_url">Download</b-link>
                  </template>


                  

                  <template #cell(Action)="data">
                    <div  class="d-flex flex-wrap gap-2">
                      <b-button variant="outline-primary" @click="finishEFT(data.item)"  v-if="data.item.status =='PENDING' || data.item.status == 'PROGRESS'" >Confirm</b-button>
                      <b-button class="btn btn-soft-light" @click="cancelEFT(data.item)"  v-if="data.item.status =='PENDING' || data.item.status == 'PROGRESS'" >Cancel</b-button>
                    </div>
                  </template>

                  <template v-slot:row-details="row">
                    <div class="table align-middle table-nowrap" style="margin-left:30px;">
                      <b-table
                        :items="row.item.cash_transactions"
                        :fields="[{key : 'Payee'},{key : 'Date'},{key :'Amount'}]"
                        responsive="sm"
                        :per-page="row.item.cash_transactions.length"

                        class="table-check col-md-10"
                      >
                        <template  #cell(Payee)="data">
                          {{ data.item.payee }}
                        </template>
                        <template  #cell(Date)="data">
                          {{ data.item.transaction_date }}
                        </template>
                        <template  #cell(Amount)="data">
                          ${{ data.item.amount.toLocaleString() }}
                        </template>


                      </b-table>
                    </div>
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
